import React from 'react';

const defaultState = {
  show: false,
  toggleShow: () => {},
};

const MenuContext = React.createContext(defaultState);

class MenuProvider extends React.Component {
  state = {
    show: false,
  };

  toggleShow = () => {
    let show = !this.state.show;
    this.setState({ show });
  };

  render() {
    const { children } = this.props;
    const { show } = this.state;
    return (
      <MenuContext.Provider
        value={{
          show,
          toggleShow: this.toggleShow,
        }}
      >
        {children}
      </MenuContext.Provider>
    );
  }
}

export default MenuContext;

export { MenuProvider };
